export const BASE_ROUTE = "";

export const API_BASE_URL = "/api";

function getCSRFToken() {
    const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/);
    return csrfCookie ? csrfCookie[1] : "";
}

const Api = {
    get: async (url) => {
        try {
            const response = await fetch(API_BASE_URL + url, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                // eslint-disable-next-line no-throw-literal
                throw {
                    message:
                        "Request failed with status code " + response.status,
                    status: response.status,
                };
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    },

    post: async (url, data = {}) => {
        const csrfToken = getCSRFToken();

        try {
            const response = await fetch(API_BASE_URL + url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                },
                body: JSON.stringify(data),
            });
            let json: any = {};
            try {
                json = await response.json();
            } catch (error) {}

            if (!response.ok) {
                // eslint-disable-next-line no-throw-literal
                throw {
                    message:
                        "Request failed with status code " + response.status,
                    status: response.status,
                    data: json,
                };
            }
            return json;
        } catch (error) {
            throw error;
        }
    },

    put: async (url, data = {}) => {
        const csrfToken = getCSRFToken();

        try {
            const response = await fetch(API_BASE_URL + url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                },
                body: JSON.stringify(data),
            });
            let json: any = {};
            try {
                json = await response.json();
            } catch (error) {}
            if (!response.ok) {
                // eslint-disable-next-line no-throw-literal
                throw {
                    message:
                        "Request failed with status code " + response.status,
                    status: response.status,
                    data: json,
                };
            }
            return json;
        } catch (error) {
            throw error;
        }
    },

    delete: async (url, jsonData = null) => {
        const csrfToken = getCSRFToken();

        try {
            const response = await fetch(API_BASE_URL + url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                },
                body: jsonData ? JSON.stringify(jsonData) : null,
            });
            if (!response.ok) {
                throw new Error(
                    "Request failed with status code " + response.status,
                );
            }
            let json: any = {};
            try {
                json = await response.json();
            } catch (error) {}
            return json;
        } catch (error) {
            throw error.message;
        }
    },
};

export default Api;
