import { useEffect, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css"; // Import Quill styles
import DOMPurify from "dompurify";

const QuillEditor = ({ initValue, value, onChange }) => {
    const [editor, setEditor] = useState(null);
    useEffect(() => {
        const _editor = new Quill("#quill_editor", {
            theme: "snow", // Specify theme ('snow' or 'bubble')
            modules: {
                toolbar: [
                    ["bold", "italic", "underline", "blockquote"],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                ],
            },
            placeholder: "Skriv her",
        });

        _editor.root.setAttribute("spellcheck", "true0");
        _editor.on("text-change", () => {
            onChange(_editor.root.innerHTML);
        });

        setEditor(_editor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!editor || !initValue) return;
        const sanitizedHTML = DOMPurify.sanitize(initValue);

        editor.clipboard.dangerouslyPasteHTML(sanitizedHTML);
        editor.insertText(0, "\n");
        editor.insertText(editor.getLength(), "\n");
    }, [initValue, editor]);

    return <div id="quill_editor" />;
};

export default QuillEditor;
