import { useMsal } from "@azure/msal-react";
import UserContext from "../../context/userContext";
import { useContext, useEffect, useState } from "react";
import { LoginFailed } from "./LoginFailed";
import Api from "../../api";
import { Navigate } from "react-router-dom";
import { LoadingCenterButton } from "../../components/Loading";

export default function Login() {
    const { instance } = useMsal();
    const { setUserData } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [loginFailed, setLoginFailed] = useState(false);

    async function getUser() {
        let activeAccount = await instance.getActiveAccount();
        if (activeAccount == null) return;

        const token = await instance.acquireTokenSilent({
            scopes: ["User.Read"],
        });

        try {
            const data = {
                access_token: token?.accessToken,
            };

            const res = await Api.post("/login", data);

            setUserData(res);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setLoginFailed(true);
        }
    }

    useEffect(() => {
        if (
            !instance.getActiveAccount() &&
            instance.getAllAccounts().length > 0
        ) {
            instance.setActiveAccount(instance.getAllAccounts()[0]);
        }

        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance]);

    if (isLoading) return <LoadingCenterButton />;
    if (loginFailed) return <LoginFailed />;

    return <Navigate to="/" replace={true} />;
}
