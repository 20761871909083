import { Typography, Grid } from "@mui/material";
import { Tile } from "./dashboard";
import Chart from "chart.js/auto";
import { useEffect } from "react";
import style from "./dashboard.module.css";
import Api from "../../api";
import { useLoaderData } from "react-router-dom";
import { IAdminDashboard } from "../../interface";

const defaultChartOptions = {
    plugins: {
        legend: {
            display: true,
        },
    },
    maintainAspectRatio: false,
    responsive: true,
    resizeDelay: 5,
    scales: {
        y: {
            grace: 0.5,
            beginAtZero: true,
        },
    },

    elements: {
        point: {
            radius: 0,
        },
    },
};

export async function adminDashboardLoader() {
    return Api.get("/admin_dashboard");
}

export default function AdminDashboard() {
    const data: IAdminDashboard = useLoaderData() as IAdminDashboard;
    const { stats, cases } = data;

    useEffect(() => {
        const data = {
            labels: cases.chart.labels,
            datasets: [
                {
                    label: "Åpnet",
                    data: cases.chart?.opened,
                    lineTension: 0.1,
                },
                {
                    label: "Lukket",
                    data: cases.chart?.closed,
                    lineTension: 0.1,
                },
            ],
        };

        const ctx: any = document.getElementById("ticketsStats");
        const chart = new Chart(ctx, {
            type: "bar",
            data: data,
            options: defaultChartOptions,
        });

        return () => {
            chart.destroy();
        };
    }, [cases.chart?.closed, cases.chart.labels, cases.chart?.opened]);

    /*
    useEffect(() => {
        const ctx: any = document.getElementById("mfaUsers");
        const chart = new Chart(ctx,
            {
                type: 'bar',
                data: {
                    labels: ['Multifactor', 'Insecure'],
                    datasets: [{
                        data: [65, 10],
                        borderWidth: 1
                    }]
                },
                options: {
                    plugins: {
                        legend: {
                            display: false
                        }
                    },
                },
            }
        );

        return () => {
            chart.destroy();
        };
    }, []); */

    return (
        <>
            <div className="pageHeaderWrapper">
                <Typography variant="h6" className="pageHeader">
                    Bedrift Dashboard
                </Typography>
            </div>
            <div className="paddingPages" style={{ padding: "1rem" }}>
                <Grid container spacing={4} sx={{}}>
                    <Tile
                        key={1}
                        title={"Alle saker"}
                        value={stats.total_cases_count}
                        desc={"Alle åpne og lukkede saker"}
                        action={{
                            label: "Vis alle",
                            link: "/cases",
                        }}
                    />
                    <Tile
                        key={4}
                        title={"Brukere"}
                        value={stats.users_count}
                        desc={"Antall brukere i organisasjonen"}
                        action={{
                            label: "Vis alle",
                            link: "/users",
                        }}
                    />
                    <Tile
                        key={2}
                        title={"Enheter"}
                        value={stats.device_count}
                        desc={"Antall enheter i organisasjonen"}
                        action={{
                            label: "Vis alle",
                            link: "/devices",
                        }}
                    />
                    <Tile
                        key={3}
                        title={"Lisenser"}
                        value={stats.license_count}
                        desc={"Antall lisenser i organisasjonen"}
                    />
                </Grid>
                <Grid container spacing={3} sx={{ marginTop: "0.5rem" }}>
                    <Grid item md={12}>
                        <div className={style.ticketChartWrapper}>
                            <Typography variant="h6">Saksoversikt</Typography>
                            <Typography
                                variant="caption"
                                color={"text.secondary"}
                            >
                                Her kan du se antall åpnet og lukket saker de
                                siste 30 dagene
                            </Typography>

                            <Grid container>
                                <Grid item md={12}>
                                    <div id="container">
                                        <canvas
                                            id="ticketsStats"
                                            className={style.ticketStatsChart}
                                        ></canvas>
                                    </div>
                                </Grid>
                                {/*<Grid item md={2}>
                                <Grid container direction={"column"} justifyContent={"space-between"} sx={{ height: "100%", textAlign: "center", width: "100%" }}>

                                    <Grid item>
                                        <Typography variant="h5">12</Typography>

                                        <Typography variant="body1">Antall Åpne saker</Typography>

                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5">12</Typography>

                                        <Typography variant="body1">Antall Lukkede saker</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5">12</Typography>

                                        <Typography variant="body1">Gjennomsnittelig besvarerlsestid</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>*/}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

                {false && (
                    <Grid item md={12}>
                        <div className={style.ticketChartWrapper}>
                            <Typography variant="h6">Brukere</Typography>
                            <Typography
                                variant="caption"
                                color={"text.secondary"}
                            >
                                oversikt over brukere
                            </Typography>
                            <Grid container sx={{ marginTop: "1rem" }}>
                                <Grid item>
                                    <Typography variant="body2">
                                        Sikkerhet
                                    </Typography>
                                    <div id="container">
                                        <canvas id="mfaUsers"></canvas>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        Enheter
                                    </Typography>
                                    <div id="container"></div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                )}
            </div>
        </>
    );
}
