import { useContext, useEffect, useState } from "react";
import { Typography, Grid, IconButton, Button } from "@mui/material";
import Api from "../../api";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import Paginator from "../../components/Paginator";
import style from "./userList.module.css";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { empty_user } from "./userList";
import EditModal, { MODAL_EDIT_TYPE } from "./OrderModal";
import dayjs from "dayjs";
import UserContext from "../../context/userContext";

export async function usersOrdersLoader() {
    return Api.get("/users/orders/");
}

export default function UsersOrders(props) {
    const data: any = useLoaderData();
    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const { getAccountTypeName } = useContext(UserContext);

    const [result, setResult] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [user, setUser] = useState(empty_user);

    useEffect(() => {
        setResult(data.results);
    }, [data]);

    return (
        <>
            <EditModal
                show={showModal}
                close={() => {
                    setShowModal(false);
                    revalidator.revalidate();
                }}
                user={user}
                setUser={setUser}
                isEditingUser={true}
                editModalType={MODAL_EDIT_TYPE.UPDATE_ORDER}
                setResult={setResult}
            />
            <div className="pageHeaderWrapper">
                <Typography variant="h6" className="pageHeader">
                    Bestilte brukere
                </Typography>
            </div>

            <div className="paddingPages">
                <Grid
                    container
                    alignContent={"center"}
                    justifyContent={"space-between"}
                    sx={{ padding: "1rem" }}
                >
                    <Grid item>
                        {
                            <Button
                                variant="outlined"
                                startIcon={<KeyboardReturnIcon />}
                                size="small"
                                onClick={() => {
                                    navigate("/users");
                                }}
                            >
                                Vis alle brukere
                            </Button>
                        }
                    </Grid>
                </Grid>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Brukertype</th>
                            {/*<th>Epost-adresse</th>*/}
                            <th>Mobilnummer</th>
                            <th>Stilling</th>
                            <th>Aktiveringsdato</th>
                            <th></th>
                            {/*<th></th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {result?.map((user: any) => (
                            <tr key={user.id}>
                                <td>
                                    {user.first_name} {user.last_name}
                                </td>
                                <td>{getAccountTypeName(user.account_type)}</td>
                                {/*<td>{user.email_address}</td>*/}
                                <td>{user.mobile_phone_number}</td>
                                <td>{user.position}</td>
                                <td>
                                    {dayjs(user.activation_date).format(
                                        "DD/MM/YYYY",
                                    )}
                                </td>
                                <td></td>
                                {/*<td>
                                    {
                                        <IconButton
                                            onClick={() => {
                                                setUser(user as any);
                                                setShowModal(true);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    }
                                </td>*/}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {result.length === 0 && (
                    <div className={style.placeholderOrderlist}>
                        Ingen brukere
                    </div>
                )}
                <Paginator setResult={(_res) => setResult(_res)} />
            </div>
        </>
    );
}

 