import { useMsal } from "@azure/msal-react";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";

export function LoginFailed() {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    return (
        <div>
            <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                style={{ height: "100vh", textAlign: "center" }}
            >
                <Grid item>
                    <h1>Login Failed</h1>
                    <p>Missing priviliges for {activeAccount?.username}</p>
                    <p>Tenant: {activeAccount?.tenantId} </p>
                    <Button
                        onClick={() => {
                            instance.logoutRedirect();
                        }}
                        variant={"contained"}
                    >
                        Logout and try with another account
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
