import { Typography } from "@mui/material";

export default function Licenses() {
    return (
        <div>
            <Typography variant="h5" sx={{ marginTop: "2rem" }}>
                Lisenser
            </Typography>
            <div className={"separator"}></div>
        </div>
    );
}
