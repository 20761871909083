import { Button } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { IDefaultRouterData } from "../interface";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../api";

export default function Paginator(props: any) {
    const data: IDefaultRouterData = useLoaderData() as IDefaultRouterData;
    const location = useLocation();
    const [pageCount, setPageCount] = useState(1);

    useEffect(() => {
        /*
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        }); */
        async function fetchData() {
            const res = await Api.get(
                location.pathname + "/?page=" + pageCount,
            );

            props.setResult(res.results);
        }
        if (pageCount > 1) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount]);

    if (data?.page_count > pageCount) {
        return (
            <div style={{ width: "100%", textAlign: "center" }}>
                <Button
                    onClick={() => {
                        setPageCount(pageCount + 1);
                    }}
                    variant="outlined"
                    sx={{ margin: "1rem" }}
                >
                    Hent Flere
                </Button>
            </div>
        );
    }
    /*
    return (
        <Pagination count={data.page_count} sx={{margin: "2rem 0 2rem 0"}} onChange={setParam} />
    ) */
}
