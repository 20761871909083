import React, { useState } from "react";
import { IEntraUser } from "../interface";
import _ from "lodash";

const UserContext = React.createContext<any>({});

export const UserProvider = (props: any) => {
    const { children } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUser] = useState<IEntraUser>();
    const [snackbarText, setSnackbarText] = useState("");

    const setUserData = (usrData?: IEntraUser) => {
        if (usrData) {
            setUser(usrData);
        }
        setIsLoading(false);
    };

    const getAccountTypeName = (group_name: string) => {
        if (userData) {
            let account_types = userData?.tenant?.account_types;
            let group = _.find(account_types, { group_name: group_name });
            if (group) return group.name;
        }
        return "";
    };

    return (
        <UserContext.Provider
            value={{
                isLoading,
                setUserData,
                userData,
                snackbarText: snackbarText,
                setSnackbarText,
                getAccountTypeName,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
