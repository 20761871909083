export default function IconWithText(props) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
            }}
        >
            {props.children}
        </div>
    );
}
