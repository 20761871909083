import {
    Grid,
    Typography,
    TextField,
    Tabs,
    Tab,
    Button,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Container,
    Box,
} from "@mui/material";
import Api from "../../api";
import style from "./dashboard.module.css";
import { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TerminalIcon from "@mui/icons-material/Terminal";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ITicket, IDashboard, ILicenseAssignment } from "../../interface";
import BmsSubmitModal from "../tickets/TicketSubmitModal";
import AddIcon from "@mui/icons-material/Add";
import { TicketViewer } from "../tickets/ticketViewer";
import IconWithText from "../../components/IconWithText";
import { DeviceTable } from "../devices/Devices";
import UserContext from "../../context/userContext";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export async function dashboardLoader() {
    return Api.get("/dashboard");
}

export function Tile(props) {
    const navigate = useNavigate();

    return (
        <Grid item md={3}>
            <div className={style.tileWrapper}>
                <div className={style.tileheader}>
                    <Typography variant="body1">{props.title}</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                        {props.desc}
                    </Typography>
                </div>
                <div className={style.tilecontent}>
                    <Typography variant="h5" sx={{ marginTop: "0.5rem" }}>
                        {props.value}
                    </Typography>
                    {props.action && (
                        <Button
                            onClick={() => {
                                props.action.link
                                    ? navigate(props.action.link)
                                    : props?.action?.click();
                            }}
                        >
                            {props.action.label}
                        </Button>
                    )}
                </div>
            </div>
        </Grid>
    );
}

export function LicenseViewer(props: { result: ILicenseAssignment[] }) {
    if (props.result?.length === 0) {
        return (
            <div className={"iconPlaceholder"}>
                <TerminalIcon sx={{ transform: "scale(2)" }} />
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                    Ingen lisenser
                </Typography>
            </div>
        );
    }

    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item sx={{ marginTop: "2rem" }} md={3}>
                    <Typography variant="body2">
                        Du har følgende lisenser
                    </Typography>
                    <div className="separator"></div>

                    {props.result?.map((lis) => (
                        <ListItem key={lis.license.name}>
                            <ListItemAvatar>
                                <Avatar>
                                    <TerminalIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                color="red"
                                primary={lis.license.name}
                            />
                        </ListItem>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}

export function ChatViewer(props) {
    const [chatInput, setChatInput] = useState("");
    const [chatLoading, setChatLoading] = useState(true);
    const [chatHistory, setChatHistory] = useState(null);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const data = await Api.get("/chat");
                setChatHistory(data?.result);
            } catch (e) {}
            setChatLoading(false);
        };
        fetchChatHistory();
    }, []);

    async function sendChatMessage() {
        setChatLoading(true);
        try {
            const data = await Api.post("/chat", { chat_message: chatInput });
            setChatHistory(data.result);
        } catch (e) {
            console.log(e);
        }
        setChatLoading(false);
        setChatInput("");
    }

    async function handleKeyPress(event) {
        if (event.key === "Enter") {
            if (chatInput && chatInput.length > 0) {
                sendChatMessage();
            }
            event.preventDefault();
        }
    }

    async function deleteChatHistory() {
        try {
            await Api.delete("/chat");
            setChatHistory(null);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const element = document.getElementById("chatContainer");
        element.scrollTo(0, element.scrollHeight);
    }, [chatHistory]);

    async function transformChatToBmscase() {
        let quoteHtmlString = chatHistory
            ?.map((msgObj, i) =>
                i > 0
                    ? `<blockquote><b>${msgObj.role === "user" ? "Du skrev" : "IT.no"}: </b> ${msgObj.content}</blockquote>`
                    : "",
            )
            .join("");
        quoteHtmlString = "<p>Fra chatten</p>" + quoteHtmlString + "<p></p>";

        props.setInitBmsHtml(quoteHtmlString);
        // setShowBmsModal(true);
    }

    return (
        <>
            <div className={style.chatBotWrapper}>
                <div style={{ position: "relative", width: "100%" }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        sx={{ paddingRight: "1rem", height: "3rem" }}
                    >
                        <Grid item></Grid>
                        <Grid item>
                            {chatHistory?.length > 0 && (
                                <Button
                                    startIcon={<DeleteIcon />}
                                    variant="text"
                                    color="warning"
                                    size="small"
                                    onClick={() => deleteChatHistory()}
                                >
                                    Slett historikk
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <div className={style.chatPlaceholder}>
                        {(chatHistory === null || chatHistory?.length < 2) && (
                            <>
                                <SupportAgentIcon
                                    sx={{ transform: "scale(2)" }}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{ marginTop: "1rem" }}
                                >
                                    Spør vår Chatbot
                                </Typography>
                            </>
                        )}
                    </div>

                    <div id="chatContainer" className={style.chatSequence}>
                        {chatHistory?.map((chat, index) => {
                            if (index === 0) return <></>;
                            return (
                                <div key={index}>
                                    <div className={style.chatAuthor}>
                                        <b>
                                            {chat.role === "user"
                                                ? "Du skrev"
                                                : "IT.no"}
                                        </b>
                                    </div>
                                    <Typography variant="body1">
                                        {chat.content}
                                    </Typography>
                                </div>
                            );
                        })}
                        {chatHistory?.length > 1 && (
                            <div className={style.chatCreateCase}>
                                <Button
                                    startIcon={<AddIcon />}
                                    size="small"
                                    variant="text"
                                    onClick={transformChatToBmscase}
                                >
                                    Lag supportsak med chathistorikk
                                </Button>
                                <br />
                            </div>
                        )}
                    </div>
                    <div className={style.chatbotInput}>
                        <TextField
                            fullWidth
                            disabled={chatLoading}
                            InputProps={{
                                endAdornment: chatLoading ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={sendChatMessage}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                ),
                            }}
                            label="Skriv her"
                            variant="outlined"
                            value={chatInput}
                            onKeyDown={handleKeyPress}
                            onChange={(e) => setChatInput(e.target.value)}
                        />
                        <Typography variant="caption" color="text.secondary">
                            Hvis du ikke får svarene du trenger så kan du
                            opprett en supportsak
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );
}

function OverviewViewer(props) {
    const data: IDashboard = useLoaderData() as IDashboard;
    const [chatInput, setChatInput] = useState("");
    const [chatLoading, setChatLoading] = useState(true);
    const [chatHistory, setChatHistory] = useState(null);
    const { userData } = useContext(UserContext);
    const { setShowBmsModal, setActiveTab } = props;

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const data = await Api.get("/chat");
                setChatHistory(data?.result);
            } catch (e) {}
            setChatLoading(false);
        };
        fetchChatHistory();
    }, []);

    async function sendChatMessage() {
        setChatLoading(true);
        try {
            const data = await Api.post("/chat", { chat_message: chatInput });
            setChatHistory(data.result);
        } catch (e) {
            console.log(e);
        }
        setChatLoading(false);
        setChatInput("");
    }

    async function handleKeyPress(event) {
        if (event.key === "Enter") {
            if (chatInput && chatInput.length > 0) {
                sendChatMessage();
            }
            event.preventDefault();
        }
    }

    async function deleteChatHistory() {
        try {
            await Api.delete("/chat");
            setChatHistory(null);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const element = document.getElementById("chatContainer");
        element.scrollTo(0, element.scrollHeight);
    }, [chatHistory]);

    async function transformChatToBmscase() {
        let quoteHtmlString = chatHistory
            ?.map((msgObj, i) =>
                i > 0
                    ? `<blockquote><b>${msgObj.role === "user" ? "Du skrev" : "IT.no"}: </b> ${msgObj.content}</blockquote>`
                    : "",
            )
            .join("");
        quoteHtmlString = "<p>Fra chatten</p>" + quoteHtmlString + "<p></p>";

        props.setInitBmsHtml(quoteHtmlString);
        // setShowBmsModal(true);
    }

    return (
        <>
            <div className={style.dashboardWrapperScroll} id="chatContainer">
                <Container>
                    <Box
                        sx={{
                            marginTop: "2rem",
                            height: "90vh",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                        }}
                    >
                        <Box>
                            <Grid container direction={"column"} spacing={3}>
                                <Grid item md={12}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ height: "100%" }}
                                    >
                                        <Grid item md={8}>
                                            <Typography variant={"h4"}>
                                                Hei {userData?.first_name}!
                                            </Typography>
                                            <Typography variant={"body1"}>
                                                Her kan du få oversikt over dine
                                                supportsaker og entra profil
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography variant={"h6"}>
                                                Trenger du hjelp?
                                            </Typography>
                                            <Typography variant={"body2"}>
                                                Snakk med vår chatbot og opprett
                                                support sak
                                            </Typography>
                                            <div style={{ marginTop: "1rem" }}>
                                                <Button
                                                    variant="contained"
                                                    sx={{ width: "100%" }}
                                                    onClick={() =>
                                                        setShowBmsModal(true)
                                                    }
                                                >
                                                    Opprett Supportsak
                                                </Button>
                                                {/*<Button variant="contained" sx={{ marginLeft: "1rem" }} onClick={() => setActiveTab("chat")}>Spør chatbot</Button>*/}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid
                                        container
                                        justifyContent={"center"}
                                        spacing={4}
                                    >
                                        <Tile
                                            title="Åpne saker"
                                            desc="Antall åpne saker"
                                            value={data?.cases?.length}
                                            action={{
                                                label: "Se mer",
                                                click: () =>
                                                    setActiveTab("support"),
                                            }}
                                        />
                                        <Tile
                                            title="Enheter"
                                            desc="Dine enheter"
                                            value={data?.devices?.length}
                                            action={{
                                                label: "Se mer",
                                                click: () =>
                                                    setActiveTab("device"),
                                            }}
                                        />
                                        <Tile
                                            title="Lisenser"
                                            desc="Dine lisenser"
                                            value={data?.licenses?.length}
                                            action={{
                                                label: "Se mer",
                                                click: () =>
                                                    setActiveTab("license"),
                                            }}
                                        />
                                        <Tile
                                            title="MFA"
                                            desc="Viser mfa status for din bruker"
                                            value={
                                                userData?.mfa_activated ? (
                                                    <IconWithText>
                                                        <CheckCircleOutlineIcon color="success" />
                                                        <Typography>
                                                            Godkjent
                                                        </Typography>
                                                    </IconWithText>
                                                ) : (
                                                    <IconWithText>
                                                        <PriorityHighIcon color="disabled" />
                                                        <Typography>
                                                            Ikke aktivert
                                                        </Typography>
                                                    </IconWithText>
                                                )
                                            }
                                        />
                                    </Grid>{" "}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    height: "100%",
                                    maxHeight: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                className={style.chatTextBox}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        position: "relative",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    <div className={style.diagramHeader}>
                                        <Typography
                                            variant="h6"
                                            color="text.secondary"
                                            sx={{ padding: "0.5rem 0 0 1rem" }}
                                        >
                                            AI-chat
                                        </Typography>
                                        {chatHistory?.length > 0 && (
                                            <Button
                                                startIcon={<DeleteIcon />}
                                                variant="text"
                                                size="small"
                                                onClick={() =>
                                                    deleteChatHistory()
                                                }
                                                sx={{ marginRight: "1rem" }}
                                            >
                                                Slett historikk
                                            </Button>
                                        )}
                                    </div>
                                    <div className={style.chatPlaceholder}>
                                        {(chatHistory === null ||
                                            chatHistory?.length < 2) && (
                                            <>
                                                <SupportAgentIcon
                                                    sx={{
                                                        transform: "scale(2)",
                                                    }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        marginTop: "1rem",
                                                    }}
                                                >
                                                    Spør vår AI-chat
                                                </Typography>
                                            </>
                                        )}
                                    </div>
                                    <div className={style.chatSequence}>
                                        {chatHistory?.map((chat, index) => {
                                            if (index === 0) return <></>;
                                            return (
                                                <div key={index}>
                                                    <div
                                                        className={
                                                            style.chatAuthor
                                                        }
                                                    >
                                                        <b>
                                                            {chat.role ===
                                                            "user"
                                                                ? "Du skrev"
                                                                : "IT.no"}
                                                        </b>
                                                    </div>
                                                    <Typography variant="body1">
                                                        {chat.content}
                                                    </Typography>
                                                </div>
                                            );
                                        })}
                                        {chatHistory?.length > 1 && (
                                            <div
                                                className={style.chatCreateCase}
                                            >
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    size="small"
                                                    variant="text"
                                                    onClick={
                                                        transformChatToBmscase
                                                    }
                                                >
                                                    Lag supportsak med
                                                    chathistorikk
                                                </Button>
                                                <br />
                                            </div>
                                        )}
                                    </div>
                                </Box>
                                <Box
                                    sx={{
                                        height: "5rem",
                                        paddingLeft: "1rem",
                                        paddingRight: "1rem",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        sx={{ marginTop: "1rem" }}
                                        disabled={chatLoading}
                                        InputProps={{
                                            endAdornment: chatLoading ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <IconButton
                                                    edge="end"
                                                    color="inherit"
                                                    onClick={sendChatMessage}
                                                >
                                                    <SendIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        label="Skriv her"
                                        variant="outlined"
                                        value={chatInput}
                                        onKeyDown={handleKeyPress}
                                        onChange={(e) =>
                                            setChatInput(e.target.value)
                                        }
                                    />
                                    <Typography
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        Hvis du ikke får svarene du trenger så
                                        kan du opprett en supportsak
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    );
}

function AccountViewer() {
    const { userData } = useContext(UserContext);
    const email = userData?.email_address;

    let url = "https://passwordreset.microsoftonline.com/";
    if (email) {
        url += "?username=" + email;
    }

    return (
        <>
            <Container maxWidth="xl">
                <Grid
                    container
                    sx={{ paddingTop: "2rem" }}
                    justifyContent={"center"}
                >
                    <Grid item md={5}>
                        <Typography variant={"h5"}>
                            Gjenopprette passord
                        </Typography>
                        <div className="separator"></div>
                        <p>
                            Hvis du har glemt passordet til Microsoft kontoen
                            din kan du bruke følgende portal for å sette nytt
                            passord.
                        </p>
                        <p>
                            Underveis i prosessen så vil du bli spurt om å
                            verifisere deg med enten epost, mobilnummer og eller
                            MFA.
                        </p>
                        <Button
                            variant="contained"
                            startIcon={<OpenInNewIcon />}
                            target="_blank"
                            href={url}
                        >
                            Gå til portal
                        </Button>
                        <div className="separator"></div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default function Dashboard() {
    const data: IDashboard = useLoaderData() as IDashboard;
    const { userData } = useContext(UserContext);
    const [activeCase, setActiveCase] = useState<ITicket>(null);
    const [initBmsHtmlString, setInitBmsHtml] = useState("");
    const [showBmsModal, setShowBmsModal] = useState(false);
    const [activeTab, setActiveTab] = useState("overview");

    useEffect(() => {
        if (data?.cases?.length > 0) {
            setActiveCase(data?.cases[0]);
        }
    }, [data?.cases]);

    function handleCloseBmsModal(ignorePrompt) {
        if (ignorePrompt) {
            setShowBmsModal(false);
            setInitBmsHtml("");
            return;
        }
        let res = window.confirm(
            "Du kommer til å miste innholet. Ønsker du å lukke vinduet?",
        );
        if (res) {
            setShowBmsModal(false);
            setInitBmsHtml("");
        }
    }

    return (
        <>
            <BmsSubmitModal
                show={showBmsModal}
                closeModal={handleCloseBmsModal}
                initContentHtml={initBmsHtmlString}
            />
            <div className="pageHeaderWrapper">
                <Typography variant="h6" className="pageHeader">
                    Personlig Dashboard
                </Typography>

                <div className={style.tabsWrapper}>
                    <Tabs
                        value={activeTab}
                        onChange={(e, v) => {
                            setActiveTab(v);
                        }}
                        textColor="inherit"
                        TabIndicatorProps={{
                            style: { backgroundColor: "black" },
                        }}
                    >
                        <Tab label="Oversikt" value={"overview"} />
                        <Tab label="Åpne saker" value={"support"} />
                        <Tab label="Enheter" value={"device"} />
                        <Tab label="Lisenser" value={"license"} />
                        {userData?.tenant?.has_activated_password_reset && (
                            <Tab label="Glemt passord" value={"account"} />
                        )}

                        {/*<Tab label="Chatbot" value={"chat"} />*/}
                    </Tabs>
                </div>
            </div>

            <div className={style.tabContent}>
                {activeTab === "overview" && (
                    <OverviewViewer
                        setShowBmsModal={setShowBmsModal}
                        setActiveTab={setActiveTab}
                        setInitBmsHtml={(html) => {
                            setInitBmsHtml(html);
                            setShowBmsModal(true);
                        }}
                    />
                )}
                {activeTab === "support" && (
                    <TicketViewer
                        results={data?.cases}
                        setResult={() => {}}
                        activeCase={activeCase}
                        setActiveCase={setActiveCase}
                    />
                )}
                {activeTab === "chat" && (
                    <ChatViewer
                        setInitBmsHtml={(html) => {
                            setInitBmsHtml(html);
                            setShowBmsModal(true);
                        }}
                    />
                )}
                {activeTab === "device" && (
                    <DeviceTable result={data.devices} buttonClick={null} />
                )}
                {activeTab === "license" && (
                    <LicenseViewer result={data.licenses} />
                )}
                {activeTab === "account" && <AccountViewer />}
            </div>
        </>
    );
}
