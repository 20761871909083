import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Api from "../../api";
import { useLoaderData } from "react-router-dom";
import { ITicket, IDefaultRouterData, IMessage } from "../../interface";
import PreviewTicket from "./previewTicket";
import Paginator from "../../components/Paginator";
import style from "./bmsTickets.module.css";
import DOMPurify from "dompurify";
import TodayIcon from "@mui/icons-material/Today";
import PersonIcon from "@mui/icons-material/Person";
import dayjs from "dayjs";
import IconWithText from "../../components/IconWithText";
import MailIcon from '@mui/icons-material/Mail';

const url = "/tickets";
export async function ticketsLoader() {
    return Api.get(url);
}

function EmailHeader(props: { name: string; email: string; created: string }) {
    return (
        <Grid
            container
            justifyContent={"space-between"}
            className={style.ticketHeader}
        >
            <Grid item>
                <IconWithText>
                    <PersonIcon fontSize="medium" />
                    <Typography fontWeight={500}>{props.name}</Typography>
                    <Typography>{"<" + props.email + ">"}</Typography>
                </IconWithText>
            </Grid>
            <Grid item>
                <IconWithText>
                    <TodayIcon fontSize="medium" />
                    <span>
                        {dayjs(props.created).format("HH:MM DD.MM.YYYY")}
                    </span>
                </IconWithText>
            </Grid>
        </Grid>
    );
}

export function TicketViewer(props: {
    results: ITicket[];
    setResult: any;
    activeCase: ITicket;
    setActiveCase: any;
}) {
    if (props.results?.length === 0) {
        return (
            <div className={"iconPlaceholder"}>
                <MailIcon sx={{ transform: "scale(2)" }} />
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                    Ingen åpne saker
                </Typography>
            </div>
        );
    }

    return (
        <div style={{ marginLeft: "0.4rem" }}>
            <Grid container>
                <Grid item md={4}>
                    <div className={style.previewList}>
                        {props.results.map((ticket, index) => (
                            <PreviewTicket
                                key={index}
                                case={ticket}
                                click={() => {
                                    props.setActiveCase(ticket);
                                }}
                                showExtraBorder={true}
                                isActive={props.activeCase?.id === ticket?.id}
                            />
                        ))}
                        <Paginator
                            setResult={(_res) =>
                                props.setResult(props.results.concat(_res))
                            }
                        />
                    </div>
                </Grid>
                <Grid item md={8}>
                    {props.activeCase && (
                        <div className={style.activeCase}>
                            <div style={{ padding: "2rem" }}>
                                <EmailHeader
                                    name={
                                        props.activeCase.user
                                    }
                                    email={props.activeCase.user_email}
                                    created={props.activeCase.init_time}
                                />
                                <Typography
                                    variant="h5"
                                    sx={{ marginBottom: "1rem" }}
                                >
                                    {props.activeCase?.title}
                                </Typography>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            props.activeCase?.description,
                                        ),
                                    }}
                                />
                                {props.activeCase?.messages?.map((msg: IMessage) => {
                                    return (
                                        <>
                                            <EmailHeader
                                                name={""}
                                                email={msg.sender}
                                                created={msg.timestamp}
                                            />

                                            <div
                                                key={msg.timestamp}
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(
                                                        msg.body,
                                                    ),
                                                }}
                                            />
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}

export default function Tickets() {
    const data: ITicket[] = useLoaderData() as ITicket[];
    const [results, setResult] = useState<ITicket[]>(data);
    const [activeCase, setActiveCase] = useState<ITicket>(null);
    useEffect(() => {
        if (results?.length > 0) {
            setActiveCase(results[0]);
        }
    }, [results]);

    return (
        <>
            <div className="pageHeaderWrapper">
                <Typography variant="h6" className="pageHeader">
                    Alle Saker
                </Typography>
            </div>
            <TicketViewer
                activeCase={activeCase}
                setActiveCase={setActiveCase}
                results={results}
                setResult={setResult}
            />
        </>
    );
}
