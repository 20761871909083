import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import style from "./bmsTickets.module.css";
import { ITicket } from "../../interface";

function stripHtml(html) {
    return html.replace(/<[^>]*>|&nbsp;/g, "");
}

export default function PreviewTicket(props: {
    case: ITicket;
    click: any;
    showExtraBorder?: boolean;
    isActive?: boolean;
}) {
    if (props.case === undefined) return;
    const { title, init_time, description, user_email } = props.case;

    const className = props.isActive
        ? `${style.ticketWrapperExtended} ${style.ticketWrapperExtendedMarked}`
        : style.ticketWrapperExtended;

    return (
        <div className={className} onClick={props.click}>
            <Grid
                container
                justifyContent={"space-between"}
                onClick={props.click}
            >
                <Grid item>
                    <Typography variant="body2" color={"text.secondary"}>
                        {dayjs(init_time).format("MM:HH DD.MM.YYYY")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color={"text.secondary"}>
                        {user_email}{" "}
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant="h6">{title}</Typography>
            {true && (
                <div className={style.description}>
                    <Typography variant="body2" color={"text.secondary"}>
                        {stripHtml(description)}
                    </Typography>
                </div>
            )}
        </div>
    );
}
